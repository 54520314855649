class NewsList {
    init() {
        if (window.matchMedia("(min-width: 992px)").matches) {
            this.desktopHover()
        }
    }

    desktopHover() {
        const newsListItems = document.querySelectorAll('.news__item')
        newsListItems.forEach(item => {
            let itemContent = item.querySelector('.news__item-content'),
                itemContentInner = item.querySelector('.news__item-content-inner'),
                itemText = item.querySelector('.news__item-text'),
                itemTextInner = item.querySelector('.news__item-text-inner')

            itemContent.style.height = itemContentInner.offsetHeight + 70 + 'px'

            item.addEventListener('mouseenter', (e) => {
                itemText.style.height = itemTextInner.offsetHeight + 'px'
                itemContent.style.height = item.offsetHeight + 'px'
                itemContent.classList.add('hovered')
            })
            item.addEventListener('mouseleave', (e) => {
                itemText.style.height = '0'
                itemContent.classList.remove('hovered')
                setTimeout(function (){
                    itemContent.style.height = itemContentInner.clientHeight + 70 + 'px'
                },200)
            })
        })
    }
}

export default NewsList