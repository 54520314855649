// import Swiper bundle with all modules installed
import Swiper from 'swiper/bundle';

class HomePage {
    render() {
        /**
         * Init main slider
         * */
        this.mainSlider()
    }

    mainSlider() {
        const swiper = new Swiper('.slider', {
            slidesPerView: 1,
            grabCursor: true,
            loop: true,
            pagination: {
                el: '.slider__pagination',
                clickable: true,
            },
            navigation: {
                nextEl: '.slider__nav-button--next',
                prevEl: '.slider__nav-button--prev',
            }
        })
    }
}

export default HomePage