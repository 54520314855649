// import Bootstrap
import 'bootstrap';

// import Swiper styles bundle
import 'swiper/css/bundle';

// import template styles
import './scss/main.scss'

import Menu from './js/components/menu/menu'
const menu = new Menu()
menu.init()

import HomePage from './js/home'
const homePage = new HomePage()
homePage.render()

import NewsList from './js/components/news-list/news-list'
const newsList = new NewsList()
newsList.init()

import CallbackMap from './js/components/callback-map/callback-map'
const map = new CallbackMap()
map.render()

import Showmore from './js/components/showmore/showmore'
const showmore = new Showmore()
showmore.init()

import Popup from './js/components/popup/popup'
const popup = new Popup()
popup.init()

import MaskPhone from './js/components/mask-phone/mask-phone'
const maskPhone = new MaskPhone()
maskPhone.init()

import Anchor from './js/components/anchors/anchors'
const anchor = new Anchor()
anchor.init()